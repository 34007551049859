<template>
  <div class="RealTeam">
    <h2>部门班组</h2>
    <el-button type="primary" @click="handleAdd" :disabled="onCheckBtnAuthority('RealTeamAdd')">添加</el-button>
    <div class="searchWrap">
      <el-row>
        <el-col :span="5">
          <div class="sb">
            <span>参建单位</span>
            <el-select
              v-model="corpId"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in copList"
                :key="item.corpId"
                :label="item.corpNmae"
                :value="item.corpId"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" size="small" @click="onGetListData"
            >搜索</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="corpName" label="单位"> </el-table-column>
      <el-table-column prop="teamName" label="班组名"> </el-table-column>
      <el-table-column prop="teamLeaderName" label="班组长姓名">
      </el-table-column>
      <el-table-column prop="teamLeaderPhone" label="班组长联系电话">
      </el-table-column>
      <el-table-column prop="entryTime" label="进场日期"> </el-table-column>
      <el-table-column prop="exitTime" label="退场日期"> </el-table-column>
      <el-table-column label="操作" align="right" width="80">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" :disabled="onCheckBtnAuthority('RealTeamEdit')">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="班组信息"
      :visible.sync="showFormData"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="formData" :rules="formRules" ref="ruleForm" label-width="120px">
        <el-form-item label="参建单位" prop="corpId">
          <el-select
              v-model="formData.corpId"
              clearable
              class="searchInput"
              placeholder="请选择"
              style="width:100%;"
            >
              <el-option
                v-for="item in copList"
                :key="item.corpId"
                :label="item.corpNmae"
                :value="item.corpId"
              >
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="班组名" prop="teamName">
          <el-input  v-model="formData.teamName"></el-input>
        </el-form-item>
        <el-form-item label="班组长姓名" prop="teamLeaderName">
          <el-input  v-model="formData.teamLeaderName"></el-input>
        </el-form-item>
        <el-form-item label="班组长联系电话" prop="teamLeaderPhone">
          <el-input  v-model="formData.teamLeaderPhone"></el-input>
        </el-form-item>
        <el-form-item label="进场日期" prop="entryTime">
          <el-date-picker
                style="width: 100%"
                v-model="formData.entryTime"
                value-format="yyyy-MM-dd hh:ss:mm"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
        </el-form-item>
        <el-form-item label="退场日期" prop="exitTime">
          <el-date-picker
                style="width: 100%"
                v-model="formData.exitTime"
                value-format="yyyy-MM-dd hh:ss:mm"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.RealTeam {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "RealTeam",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      copList: [],
      corpId: null,
      showFormData: false,
      formData: {
        projectId: localStorage.getItem("projectId"),
        teamId: null,
        teamName: null,
        corpId: null,
        teamLeaderName: null,
        teamLeaderPhone: null,
        teamLeaderIdCardType: null,
        teamLeaderIdCardNumber: null,
        responsiblePersonName: null,
        responsiblePersonPhone: null,
        responsiblePersonIdCardType: null,
        responsiblePersonIdCardNumber: null,
        remark: null,
        entryTime: null,
        exitTime: null,
        entryAttachmentFileIds: null,
        exitAttachmentsFileIds: null,
      },
      formRules: {
        teamName: [{ required: true, message: "必填项", trigger: "blur" }],
        corpId: [{ required: true, message: "必填项", trigger: "blur" }],
      },
    };
  },
  computed: {},
  watch: {
    corpId: function () {
      this.pageNum = 1
      this.onGetListData();
    },
  },
  mounted() {
    this.onGetPageBtn();
    this.onGetCopList();
    this.onGetListData();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1688758591826751490",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem("projectId"),
        corpId: this.corpId,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/realProjectTeam/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageSize = res.data.pageSize;
            that.pageNum = res.data.pageNum;
            that.totals = res.data.totals;
            that.listData = res.data.dataList;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 参建单位列表
    onGetCopList: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem("projectId"),
        pageSize: 10000,
        pageNum: 1,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/realProjectSubContractor/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.copList = res.data.dataList;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭
    handleClose: function () {
      this.formData.teamId = null;
      this.formData.teamName = null;
      this.formData.corpId = null;
      this.formData.teamLeaderName = null;
      this.formData.teamLeaderPhone = null;
      this.formData.teamLeaderIdCardType = null;
      this.formData.teamLeaderIdCardNumber = null;
      this.formData.responsiblePersonName = null;
      this.formData.responsiblePersonPhone = null;
      this.formData.responsiblePersonIdCardType = null;
      this.formData.responsiblePersonIdCardNumber = null;
      this.formData.remark = null;
      this.formData.entryTime = null;
      this.formData.exitTime = null;
      this.formData.entryAttachmentFileIds = null;
      this.formData.exitAttachmentsFileIds = null;

      this.showFormData = false;
      this.$refs['ruleForm'].resetFields();
    },
    // 添加
    handleAdd: function () {
      this.formData.teamId = null;
      this.formData.teamName = null;
      this.formData.corpId = null;
      this.formData.teamLeaderName = null;
      this.formData.teamLeaderPhone = null;
      this.formData.teamLeaderIdCardType = null;
      this.formData.teamLeaderIdCardNumber = null;
      this.formData.responsiblePersonName = null;
      this.formData.responsiblePersonPhone = null;
      this.formData.responsiblePersonIdCardType = null;
      this.formData.responsiblePersonIdCardNumber = null;
      this.formData.remark = null;
      this.formData.entryTime = null;
      this.formData.exitTime = null;
      this.formData.entryAttachmentFileIds = null;
      this.formData.exitAttachmentsFileIds = null;

      this.showFormData = true;
    },
    // 编辑
    handleEdit: function (row) {
      console.log(row);
      this.formData.teamId = row.teamId;
      this.formData.teamName = row.teamName;
      this.formData.corpId = row.corpId+'';
      this.formData.teamLeaderName = row.teamLeaderName;
      this.formData.teamLeaderPhone = row.teamLeaderPhone;
      this.formData.teamLeaderIdCardType = row.teamLeaderIdCardType;
      this.formData.teamLeaderIdCardNumber = row.teamLeaderIdCardNumber;
      this.formData.responsiblePersonName = row.responsiblePersonName;
      this.formData.responsiblePersonPhone = row.responsiblePersonPhone;
      this.formData.responsiblePersonIdCardType = row.responsiblePersonIdCardType;
      this.formData.responsiblePersonIdCardNumber = row.responsiblePersonIdCardNumber;
      this.formData.remark = row.remark;
      this.formData.entryTime = row.entryTime;
      this.formData.exitTime = row.exitTime;
      this.formData.entryAttachmentFileIds = row.entryAttachmentFileIds;
      this.formData.exitAttachmentsFileIds = row.exitAttachmentsFileIds;

      this.showFormData = true;
    },
    // 提交
    handleSubmit: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmit();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 执行提交
    onSubmit: function () {
      var that = this;
      var data = this.formData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/realProjectTeam/saveOrUpdate",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功!",
            });
            that.handleClose();
            that.onGetListData();
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    